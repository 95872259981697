import React from 'react';
import './App.css';
import beyoungSdgLogo from './images/Logo_beyond.png';
import funder1Logo from './images/ERC.png';
import funder2Logo from './images/logo_irees.png';
import funder3Logo from './images/logoFooter.jpg';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={beyoungSdgLogo} alt="BeYoung SDG Logo" className="Main-logo" />
        <div className='funded_by'>Funded by</div>
        <div className="Funder-logos">
         
          <img src={funder1Logo} alt="Funder 1 Logo" className="Funder-logo" />
          <img src={funder2Logo} alt="Funder 2 Logo" className="Funder-logo" />
          <img src={funder3Logo} alt="Funder 3 Logo" className="Funder-logo3" />
        </div>
      </header>
    </div>
  );
}

export default App;
